import * as React from "react";
import Layout from "@components/layout";
import SEO from "@components/seo";
import EventsComponent from "@components/Events";
import { navigate } from "gatsby";


const EventsPage = ({ uri }) => {
  /* Changes for bnb event: 1. Added UseEffect Hook and
  2. commenting out <EventsComponent> */
  
  React.useEffect(()=>{
    navigate('/events/bnb')
  });
  
  return (
    <Layout path={uri}>
    <SEO title="Upcoming Magyk Software Events" />
    {/* <EventsComponent /> */}
  </Layout>
  );          
  
};

export default EventsPage;
